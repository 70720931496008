import _ from 'lodash';
import { ReactiveObject } from '@dha/vue-composition-decorators';
import { AppModel } from '@/model/AppModel';
import { GeoProperties, SearchResult } from '@/types';

/**
 * Service for fetching and aggregating geographic search results based on a provided search query
 */
export default class RegionSearchService extends ReactiveObject {
    private readonly appModel: AppModel;
    private readonly MAX_RESULTS: number = 5;

    constructor(appModel: AppModel) {
        super();
        this.appModel = appModel;
    }

    /**
     * Fetch region search results from local data
     * @param searchText The search string to filter results by
     */
    getRegionsFromSearch(searchText: string, onlyGeoLevel?: 'state' | 'county'): SearchResult[] {
        if (onlyGeoLevel) {
            return this.getLocalSearchResults(searchText, onlyGeoLevel);
        }
        return [
            ...this.getLocalSearchResults(searchText, 'state'),
            ...this.getLocalSearchResults(searchText, 'county')
        ];
    }

    /**
     * Return an array of filtered search results from local data (e.g. counties)
     * @param searchText The search string to filter results by
     */
    private getLocalSearchResults(searchText: string, geoLevel: 'state' | 'county'): SearchResult[] {
        const query = searchText.toLowerCase();
        const allValues = _.values(this.appModel.geoLevelMeta?.[geoLevel] ?? []);

        // go through all values and find the relevant ones
        const { startsWithMatch, containsMatch } = allValues
        // filter those that include `query`
            .filter(i => i.displayName.toLowerCase().includes(query))
        // split filtered results by `startsWith` and `contains`
            .reduce((res, i: GeoProperties) => {
                const startsWith = i.displayName.toLowerCase().startsWith(query);
                res[startsWith ? 'startsWithMatch' : 'containsMatch'].push(i);
                return res;
            }, {
                startsWithMatch: [] as GeoProperties[],
                containsMatch: [] as GeoProperties[]
            });

        // sort the result sets
        startsWithMatch.sort(this.sortByDisplayName);
        containsMatch.sort(this.sortByDisplayName);

        return startsWithMatch
            .concat(containsMatch) // merge results together
            .slice(0, this.MAX_RESULTS) // get the amount we want
            .map(region => ({ // format response
                name: region.displayName,
                fips: region.fips,
                geoLevel
            }));
    }

    // eslint-disable-next-line class-methods-use-this
    private sortByDisplayName(a: GeoProperties, b: GeoProperties): number {
        return (a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1);
    }

    /**
     * Split the string into words and return how many words start with the substring
     * @param string The string to search in
     * @param substring The substring to search for
     */
    private static startsWithCount(string: string, substring: string): number {
        if (string.length === 0 || substring.length === 0) {
            return 0;
        }

        return string
            .split(' ')
            .reduce((acc, word) => (word.startsWith(substring) ? acc + 1 : acc), 0);
    }
}
