











/* eslint-disable @typescript-eslint/camelcase */

import Vue from 'vue';

import BigMapSection from '@/views/BigMapSection/BigMapSection.vue';

import { setupModelsAndServices } from './setup';

const {
    appModel,
    dataService,
} = setupModelsAndServices();

export default Vue.extend({
    appModel,
    components: {
        BigMapSection,
    },
    computed: {
        textBlocks() {
            return appModel.metadata?.textBlocks ?? {
                INTRO: {},
                OVERALL_MAP: {},
                THEME_MAPS: {},
                BAR_CHART: {},
                DOSES_MAP: {},
                BIG_NUMBERS: {},
                READ_THE_REPORT: {},
                ABOUT: {},
                CAVEATS: {},
                DESERT_MAP: {},
            };
        },
    },
    watch: {

    },
    async mounted() {
        await dataService.init();
        await appModel.init();
        // @ts-ignore
        window.appModel = appModel;
    },
});
