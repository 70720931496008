/* eslint-disable no-param-reassign */

import { csvParse, DSVRowArray } from 'd3-dsv';
import {
    IncomingTemporalByFipsData,
    IncomingBigNumbersData,
    ParsedGeojsonFeatureCollection,
    ParsedGeoLevel,
    ParsedBigNumberData,
    ParsedMetadata,
    ParsedMetricByFipsData,
    ParsedMetricMetadata,
} from '../parsers';
import { DataSource, QueryLatestFilters, QueryTemporalFilters } from './DataSource';
import { csvToMetricData, csvToTemporalData, temporalCsvToLatestData } from './helpers';

type Files = Record<string, Promise<DSVRowArray<string>>>;
type BigNumberFiles = Record<string, Promise<object>>;

export default class FileDataSource implements DataSource {
    fileBaseUrl: string;
    metadata?: ParsedMetadata;
    files: Files;
    bigNumberFiles: BigNumberFiles;

    constructor(fileBaseUrl: string) {
        this.fileBaseUrl = fileBaseUrl;
        this.files = {};
        this.bigNumberFiles = {};
    }

    async init() {
        this.metadata = await this.fetchAndBuildMetadata();
    }

    // eslint-disable-next-line class-methods-use-this
    private async getJsonFile<T>(file: string): Promise<T> {
        const request = await fetch(file);
        return request.json();
    }

    // eslint-disable-next-line class-methods-use-this
    private async getCsvFile(file: string) {
        const request = await fetch(file);
        return csvParse(await request.text());
    }

    private async fetchAndBuildMetadata(): Promise<ParsedMetadata> {
        return this.getJsonFile<ParsedMetadata>('metadata.json');
    }

    private loadCsvData(
        id: string,
        csv: Promise<DSVRowArray<string>>
    ) {
        if (!this.files[id]) {
            this.files[id] = csv;
        }
    }

    private loadJsonData(
        id: string,
        json: Promise<IncomingBigNumbersData>
    ) {
        if (!this.bigNumberFiles[id]) {
            this.bigNumberFiles[id] = json;
        }
    }

    async getMetadata() {
        if (!this.metadata) {
            return this.fetchAndBuildMetadata();
        }
        return this.metadata;
    }

    async getLatestMetricData(
        metadata: ParsedMetricMetadata,
        filters: QueryLatestFilters,
    ): Promise<ParsedMetricByFipsData[]> {
        const { geoLevel, groupingId, fipsCode } = filters;
        const dataSource = metadata.dataSources[geoLevel];
        const grouping = groupingId ?? 'total';
        const query = dataSource[grouping]?.source;
        if (!dataSource.available || !dataSource[grouping] || !query) {
            const res: ParsedMetricByFipsData[] = [];
            return Promise.resolve(res);
        }

        let cached = this.files[query];
        if (!cached) {
            const file = this.getCsvFile(`${this.fileBaseUrl}/${query}`);
            this.loadCsvData(query, file);
            cached = this.files[query];
            if (!cached) {
                const res: ParsedMetricByFipsData[] = [];
                return Promise.resolve(res);
            }
        }

        // Return the latest State data, filtered by fipsCode if necessary
        if (metadata.isTemporal) {
            return temporalCsvToLatestData(await cached, dataSource[grouping].key, fipsCode);
        }

        return csvToMetricData(await cached, dataSource[grouping].key);
    }

    async getTemporalData(
        metadata: ParsedMetricMetadata,
        filters: QueryTemporalFilters,
    ): Promise<IncomingTemporalByFipsData[]> {
        const { geoLevel, groupingId } = filters;
        const grouping = groupingId ?? 'total';
        const dataSource = metadata.dataSources[geoLevel];
        const query = dataSource[grouping]?.source;
        if (!dataSource.available || !dataSource[grouping] || !query) {
            const res: IncomingTemporalByFipsData[] = [];
            return Promise.resolve(res);
        }

        let cached = this.files[query];
        if (!cached) {
            const file = this.getCsvFile(`${this.fileBaseUrl}/${query}`);
            this.loadCsvData(query, file);
            cached = this.files[query];
            if (!cached) {
                const res: IncomingTemporalByFipsData[] = [];
                return Promise.resolve(res);
            }
        }

        return csvToTemporalData(await cached, dataSource[grouping].key, filters);
    }

    async getGeojson(
        geoLevel: ParsedGeoLevel,
        reprojected: boolean
    ): Promise<ParsedGeojsonFeatureCollection> {
        if (reprojected) {
            return this.getJsonFile<ParsedGeojsonFeatureCollection>(
                `data/${geoLevel}-albersusa.geojson`
            );
        }
        return this.getJsonFile<ParsedGeojsonFeatureCollection>(`data/${geoLevel}.geojson`);
    }

    async getBigNumberData(
        metadata: ParsedMetricMetadata,
        filters: QueryTemporalFilters
    ): Promise<ParsedBigNumberData> {
        const { geoLevel, groupingId } = filters;
        const grouping = groupingId ?? 'total';
        const dataSource = metadata.dataSources[geoLevel];
        const query = dataSource[grouping]?.source;

        const bigNumber: ParsedBigNumberData = {
            metricId: metadata.metricId,
            value: 0,
        };

        if (!dataSource.available || !dataSource[grouping] || !query) {
            return Promise.resolve(bigNumber);
        }

        let cached = this.bigNumberFiles[query];

        if (!cached) {
            const file = this.getJsonFile<IncomingBigNumbersData>(`${this.fileBaseUrl}/${query}`);
            this.loadJsonData(query, file);
            cached = this.bigNumberFiles[query];

            if (!cached) {
                return Promise.resolve(bigNumber);
            }
        }

        const data = await cached;

        bigNumber.value = data[metadata.metricId].high ?? 0;

        return bigNumber;
    }
}
