import * as t from 'io-ts';

export const GeoLevelType = t.keyof({
    national: null,
    state: null,
    county: null
});

const DataSourceType = t.type({
    source: t.string,
    key: t.string
});

const DataSourcesType = t.type({
    available: t.boolean,
    total: t.union([t.null, DataSourceType]),
    '3q': t.union([t.null, DataSourceType]),
    '5q': t.union([t.null, DataSourceType])
});

export const DataSourceKeyType = t.keyof({
    total: null,
    '3q': null,
    '5q': null
});

const BaseMetricMetadataType = t.type({
    metricId: t.string,
    label: t.string,
    isTemporal: t.boolean,
    dataSources: t.record(GeoLevelType, DataSourcesType),
    color: t.string,
    secondaryColor: t.string,
    colors: t.array(t.string),
    source: t.string,
    units: t.string,
    shortName: t.string
}, 'MetricMetadata');

export const BaseBigNumberMetricMetadataType = t.intersection([BaseMetricMetadataType, t.type({
    type: t.union([t.literal('nominal'), t.literal('percent')]),
    iconPath: t.string,
    text: t.string,
    extentOptions: t.type({
        kind: t.literal('static'),
        extent: t.tuple([t.number, t.number])
    })
})]);

const extentOptionsTypeBuilder = <T extends t.Type<any>>(type: T) => t.union([t.type({
    kind: t.literal('static'),
    extent: type
}), t.type({
    kind: t.literal('dynamic'),
    // extentByGeoLevel: t.record(GeoLevelType, type)
})]);

const PercentMetricMetadataType = t.intersection([BaseMetricMetadataType, t.type({
    type: t.literal('percent'),
    extentOptions: extentOptionsTypeBuilder(t.tuple([t.number, t.number]))
})]);
const NominalMetricMetadataType = t.intersection([BaseMetricMetadataType, t.type({
    type: t.literal('nominal'),
    extentOptions: extentOptionsTypeBuilder(t.tuple([t.number, t.number]))
})]);
const CategoricalMetricMetadataType = t.intersection([BaseMetricMetadataType, t.type({
    type: t.literal('categorical'),
    extentOptions: t.type({
        kind: t.literal('static'),
        extent: t.array(t.string)
    }),
    colors: t.array(t.string),
    displayValues: t.array(t.string)
})]);

export const MetricMetadataType = t.union([
    PercentMetricMetadataType,
    NominalMetricMetadataType,
    CategoricalMetricMetadataType,
    BaseBigNumberMetricMetadataType
]);

export const MetadataType = t.type({
    defaults: t.union([t.partial({
        topMetricId: t.string,
        themeMetricIds: t.array(t.type({
            id: t.string,
            submetrics: t.array(t.string)
        })),
        rolloutLevel1MetricId: t.string,
        rolloutLevel2MetricId: t.string,
        bigNumberMetricIds: t.array(t.string),
        desertMetricId: t.string,
        geoLevel: GeoLevelType
    }), t.undefined]),
    metrics: t.array(MetricMetadataType),
    bigNumberMetrics: t.array(BaseBigNumberMetricMetadataType),
    textBlocks: t.record(t.string, t.type({
        title: t.string,
        description: t.string,
        tinyTitle: t.string
    })),
    sidebarLinks: t.array(t.type({
        text: t.string,
        url: t.string
    })),
    footerLinks: t.array(t.type({
        text: t.string,
        url: t.string
    })),
    iconLinks: t.array(t.type({
        key: t.string,
        url: t.string
    })),
    downloadLinks: t.array(t.type({
        key: t.string,
        text: t.string,
        url: t.string,
    }))
}, 'Metadata');
