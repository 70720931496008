import { ParsedGeoLevel, ParsedMetricMetadata } from '@/services/DataService/parsers';
import format from '@/format';
import { ScaleOrdinal, ScaleQuantize } from 'd3-scale';
import { FormatOptions } from '@/types';

export type ColorScale = ScaleQuantize<string, string> | ScaleOrdinal<string, string, string>;
export type QuantizeScale = ScaleQuantize<number> | ScaleOrdinal<string, number, number>;
export type NumericDomain = [number, number];
export type CategoricalDomain = string[];

export type CategoricalLegendValue = {
    kind: 'categorical';
    label: string;
    color: string;

};
export type ThresholdLegendValue = {
    kind: 'threshold';
    labels: [string, string];
    color: string;
};
export type LegendValue = CategoricalLegendValue | ThresholdLegendValue;
export abstract class Metric {
    metadata: ParsedMetricMetadata;

    constructor(metadata: ParsedMetricMetadata) {
        this.metadata = metadata;
    }

    format(value: number | string | null, options?: FormatOptions) {
        if (value === null || value === undefined) {
            return 'NA';
        }
        return format(value, {
            type: this.metadata.type,
            units: this.metadata.units,
            ...options
        });
    }

    groupedFormat(value: number | string | null, options?: FormatOptions) {
        return this.format(value, {
            type: this.metadata.type,
            ...options
        });
    }

    get title() {
        return `${this.metadata.label ?? this.metadata.metricId}`;
    }

    isGeoLevelAvailable(geoLevel: ParsedGeoLevel): boolean {
        return this.metadata.dataSources[geoLevel].available;
    }

    abstract quantizeBuckets: number;
    abstract quantizeColors: string[];
    // abstract getDomain(geoLevel: ParsedGeoLevel): [number, number] | string[];
    abstract getDomain(values: (number | string | null)[]): NumericDomain | CategoricalDomain;
    abstract getLegendValues(domain: NumericDomain): LegendValue[];
    abstract getColorScale(domain: NumericDomain): ColorScale;
    abstract getQuantizeScale(domain: NumericDomain): QuantizeScale;

    abstract valuesBelowExtent(domain: NumericDomain, values: (number | string | null)[]): boolean;
    abstract valuesAboveExtent(domain: NumericDomain, values: (number | string | null)[]): boolean;
    abstract getDisplayValue(value: number | string | null): string;
    abstract getColor(domain: NumericDomain | CategoricalDomain, value: number | string | null): string;
}
