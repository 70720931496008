module.exports = {
    future: {
    // removeDeprecatedGapUtilities: true,
    // purgeLayersByDefault: true,
    },
    purge: [
        './src/**/*.vue',
        './src/**/*.ts',
    ],
    theme: {
        screens: {
            mobile: { max: '639px' },
            sm: '640px',
            md: '768px',
            lg: '1024px',
            xl: '1280px',
            '2xl': '1440px'
        },
        extend: {
            colors: {
                // 500 should always be the main color, with lighter/darker variants
                // being smaller/larger numbers respectively
                orange: {
                    300: '#f9a65c',
                    500: '#f37940',
                    700: '#c43e27'
                },
                bluegray: {
                    300: '#cfecf3',
                    500: '#9ec5d2',
                    700: '#6f9aad',
                    900: '#3a5d74'
                },
                teal: {
                    300: '#7fccc8',
                    500: '#3f7c7d'
                },
                'dark-teal': {
                    500: '#1c393a'
                },
                navy: {
                    300: '#2a507a',
                    500: '#0e2b49',
                    700: '#0b0e17'
                },
                salmon: {
                    300: '#f49190',
                    500: '#e74d4e',
                    700: '#c23027'
                },
                pink: {
                    300: '#eba7ca',
                    500: '#b85d85',
                    700: '#792946',
                    900: '#2f1118'
                },
                yellow: {
                    300: '#faf075',
                    500: '#ffd733',
                    700: '#eaac2c',
                    900: '#c86c28'
                },
                surgogreen: {
                    300: '#e9f2d9',
                    500: '#b0d79b',
                    700: '#87c26b',
                    900: '#42833e',
                },
                surgodarkgreen: {
                    100: '#4D585E',
                    200: '#273D3F',
                    300: '#212E36'
                },
                green: {
                    100: '#CDEBE8',
                    300: '#81CCC6',
                    500: '#407A7A',
                },
                // Supporting neutrals from brand color guide
                neutral: {
                    100: '#f8f7f1',
                    300: '#e1e0d3',
                    800: '#273d3f',
                    900: '#202d35'
                },
                black: '#0e2b49',
                gray: {
                    100: '#E9EAEB',
                    200: '#D6D8DA',
                    300: '#C3C3C3',
                    400: '#7B8285',
                    500: '#555555',
                    700: '#4D585E',
                    900: '#212E36'
                }
            },
            fontSize: {
                '2xs': '9px',
                xs: '10px',
                sm: '11px',
                md: '12px',
                lg: '14px',
                '2lg': '16px',
                '3lg': '20px',
                xl: '18px',
                '2xl': '24px',
                '3xl': '36px'
            },
            fontFamily: {
                zapf: ['"Zapf Humanist BT"', 'sans-serif'],
                franklin: ['"Libre Franklin"', 'sans-serif'],
                mono: ['"Botanika Mono"', '"Source Code Pro"', 'monospace'],
                code: ['"Source Code Pro"']
            },
            borderRadius: {
                3: '3px'
            },
            height: {
                'title-bar': '100px',
                'title-bar-mobile': '50px',
                'accordion-item': '46px',
                footer: '200px',
                'footer-logo': '55px',
                button: '40px',
                'button-short': '30px',
                tile: '12px',
                checkbox: '14px',
                'map-legend-container': '62px',
                'map-container': 'calc(100% - 62px)'
            },
            width: {
                button: '100px',
                'button-short-icon': '45px',
                'select-tiny': '220px',
                select: '280px',
                'accordion-small': '280px',
                accordion: '337px',
                'sidebar-mobile': '300px',
                sidebar: '400px',
                checkbox: '14px',
                search: '350px'
            },
            boxShadow: {
                selected: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                sidebar: 'rgba(0, 0, 0, 0.15) -2px 2px 4px',
                tutorial: '2px 4px 12px rgba(0, 0, 0, 0.5)'
            },
            padding: {
                sidebar: '30px'
            }
        },
    },
    variants: {},
    plugins: [],
};
