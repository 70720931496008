import _ from 'lodash';

import { Metric } from '@/model/Metric';
import { observable, computed, ReactiveObject } from '@dha/vue-composition-decorators';
import DataService from '@/services/DataService';
import { ColorScale, LegendValue, NumericDomain } from '@/model/Metric/Metric';
import { scaleQuantize } from 'd3-scale';
import { ParsedGeoLevel } from '@/services/DataService/parsers';

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface ISpatialDataset {
    metricId: string;
    data: Record<string, string | number | null>;
    colorScale: ColorScale;
    hasValuesBelowExtent: boolean;
    hasValuesAboveExtent: boolean;
    dataType: 'categorical' | 'percent' | 'nominal';
    metricTitle: string;
    buckets: number;
    colors: string[];
    legendValues: LegendValue[];

    getColor(fipsCode: string, excluded?: boolean): string;
    getDisplayValue(fipsCode: string): string;
    getIndex(fipsCode: string): number;

    updateData(
        dataService: DataService,
        metric: Metric
    ): Promise<void>;
}
export class NullSpatialDataset implements ISpatialDataset {
    metricId = 'null';
    readonly data = {};
    readonly colorScale = scaleQuantize<string, string>();
    hasValuesBelowExtent = false;
    hasValuesAboveExtent = false;
    dataType = 'percent' as 'percent';
    metricTitle = 'null';
    buckets = 5;
    colors = ['#fff', '#fff', '#fff', '#fff', '#fff'];
    legendValues = [];

    /* eslint-disable class-methods-use-this, @typescript-eslint/no-empty-function */
    getColor() { return ''; }
    getDisplayValue() { return ''; }
    getIndex() { return 0; }

    async updateData() {}
    /* eslint-enable class-methods-use-this, @typescript-eslint/no-empty-function */
}

export class SpatialDataset extends ReactiveObject implements ISpatialDataset {
    @observable.ref data: Record<string, string | number | null>;
    @observable.ref private metric: Metric;
    geoLevel: ParsedGeoLevel;
    domain: NumericDomain;

    private constructor(
        data: Record<string, string | number | null>,
        metric: Metric,
        geoLevel: ParsedGeoLevel
    ) {
        super();
        this.data = data;
        this.metric = metric;
        this.geoLevel = geoLevel;
        this.domain = this.metric.getDomain(_.values(data)) as NumericDomain;
    }

    getColor(fipsCode: string, excluded = false) {
        const domain = this.metric.getDomain(_.values(this.data));
        return this.metric.getColor(domain, excluded ? null : this.data[fipsCode]);
    }
    getDisplayValue(fipsCode: string) {
        return this.metric.getDisplayValue(this.data[fipsCode]);
    }
    getIndex(fipsCode: string) {
        const value = this.data[fipsCode];
        return _.isNil(value) ? 0 : this.quantizeScale(value as any);
    }

    @computed get metricId(): string {
        return this.metric.metadata.metricId;
    }

    @computed get colorScale(): ColorScale {
        return this.metric.getColorScale(this.domain) ?? scaleQuantize();
    }

    @computed get quantizeScale() {
        return this.metric.getQuantizeScale(this.domain) ?? scaleQuantize();
    }

    @computed get hasValuesBelowExtent() {
        return this.metric.valuesBelowExtent(this.domain, _.values(this.data));
    }
    @computed get hasValuesAboveExtent() {
        return this.metric.valuesAboveExtent(this.domain, _.values(this.data));
    }
    @computed get dataType() {
        return this.metric.metadata.type;
    }
    @computed get metricTitle() {
        return this.metric.metadata.label;
    }
    @computed get buckets() {
        return this.metric.quantizeBuckets;
    }
    @computed get colors() {
        return this.metric.quantizeColors;
    }
    @computed get legendValues() {
        return this.metric.getLegendValues(this.domain);
    }

    async updateData(dataService: DataService, metric: Metric) {
        const data = await dataService.getLatestMetricData(
            metric.metadata.metricId,
            { geoLevel: this.geoLevel }
        );
        this.data = _(data)
            .keyBy(d => d.fips)
            .mapValues(d => d.value)
            .value();
        this.metric = metric;
        this.domain = this.metric.getDomain(_.values(this.data)) as NumericDomain;
    }

    static async new(
        dataService: DataService,
        metric: Metric,
        geoLevel: ParsedGeoLevel
    ): Promise<SpatialDataset> {
        const data = await dataService.getLatestMetricData(metric.metadata.metricId, { geoLevel });
        return new SpatialDataset(
            _(data)
                .keyBy(d => d.fips)
                .mapValues(d => d.value)
                .value(),
            metric,
            geoLevel
        );
    }
}
