import _ from 'lodash';

import { ParsedMetricByDateData, ParsedMetricByFipsData, ParsedMetricMetadata } from '@/services/DataService/parsers';
import { ExtentValue } from '@/types';

type MetricData = ParsedMetricByDateData | ParsedMetricByFipsData;
type NumericalMetricData<T extends MetricData> = Omit<T, 'value'> & {value: number | null};

type CategoricalMetricMetadata = ParsedMetricMetadata & { type: 'categorical' };

export function numericalMetricData<T extends MetricData>(data: T[]): NumericalMetricData<T>[] {
    return data
        .filter(datum => datum.value !== null)
        .map(datum => ({
            ...datum,
            value: datum.value === null ? datum.value : Number(datum.value)
        }));
}

export function extentByValue(metadata: CategoricalMetricMetadata): Record<string, ExtentValue> {
    return _(metadata.extentOptions.extent)
        .map((value, i) => ({
            value,
            label: metadata.displayValues[i],
            color: metadata.colors[i]
        }))
        .keyBy('value')
        .value();
}

type TemporalValue = { cdate: Date; value: string | number | null };
export function fillNulls(sortedData: TemporalValue[]): TemporalValue[] {
    let lastDefined: string | number | null = null;
    return sortedData.map(({ value, ...rest }) => {
        if (!_.isNil(value)) {
            lastDefined = value;
        }
        return {
            ...rest,
            value: lastDefined
        };
    });
}
