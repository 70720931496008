import _ from 'lodash';
import { ParsedMetricMetadata } from '@/services/DataService/parsers';
import { scaleQuantize } from 'd3-scale';
import { isEnabled } from '@dha/feature-toggles';
import { FormatOptions } from '@/types';
import { LegendValue, Metric, NumericDomain } from './Metric';

type NumericMetricMetadata = ParsedMetricMetadata & { type: 'nominal' | 'percent' };

export class NumericMetric extends Metric {
    kind = 'numeric' as const;
    metadata: NumericMetricMetadata;

    constructor(metadata: NumericMetricMetadata) {
        // redundant constructor but needed to get types to check properly
        super(metadata);
        this.metadata = metadata;
    }

    // eslint-disable-next-line class-methods-use-this
    getDomain(values: (number | string | null)[] = []): NumericDomain {
        const extentOptions = this.metadata.extentOptions;
        if (extentOptions.kind === 'dynamic') {
            const v = values.map((i) => _.toNumber(i) ?? 0);
            return [_.min(v) ?? 0, _.max(v) ?? 0];
        }
        return extentOptions.extent;
    }

    getLegendValues(domain: NumericDomain) {
        const colorScale = this.getColorScale(domain);

        const thresholds: (number | null)[] = [
            domain[0],
            // thresholds() exists in d3-scale but not @types/d3-scale...
            // PR is currently open: https://github.com/DefinitelyTyped/DefinitelyTyped/pull/49665
            // TODO: Remove type shim when the PR is merged
            ...(colorScale as unknown as {thresholds: () => number[]}).thresholds(),
            domain[1]
        ];

        const matchFormatList = thresholds.map(threshold => threshold ?? 0);

        return colorScale.range().map((color, i) => ({
            kind: 'threshold',
            color,
            // For n colors, there are n+1 thresholds
            // color i is for the range from threshold i to threshold i + 1
            labels: [
                this.format(thresholds[i], { matchFormatList }),
                this.format(thresholds[i + 1], { matchFormatList })
            ]
        } as LegendValue));
    }

    getColorScale(domain: NumericDomain) {
        const colors = this.metadata.colors;

        return scaleQuantize<string, string>()
            .domain(domain)
            .range(colors)
            .unknown('magenta');
    }

    // eslint-disable-next-line class-methods-use-this
    getQuantizeScale(domain: NumericDomain) {
        return scaleQuantize()
            .domain(domain)
            .range([0, 1, 2, 3, 4]);
    }
    readonly quantizeBuckets = 5;
    get quantizeColors() {
        return Array.from({ length: this.quantizeBuckets }).fill(0)
            .map(() => this.metadata.color);
    }

    // eslint-disable-next-line class-methods-use-this
    valuesBelowExtent(domain: NumericDomain, values: (number | string | null)[]): boolean {
        return _.some(values, v => typeof v === 'number' && v < domain[0]);
    }

    // eslint-disable-next-line class-methods-use-this
    valuesAboveExtent(domain: NumericDomain, values: (number | string | null)[]): boolean {
        return _.some(values, v => typeof v === 'number' && v > domain[1]);
    }
    getDisplayValue(value: number | string | null) {
        return this.format(value, { decimals: 2 });
    }
    groupedFormat(value: number | string | null, options?: FormatOptions) {
        return this.format(value, {
            type: this.metadata.type,
            decimals: 2,
            ...options
        });
    }
    getColor(domain: NumericDomain, value: number | null) {
        // Much easier to see that shapes are there, just missing data
        // when they're not invisible
        const missingDataColor = isEnabled('auditMissingShapes')
            ? 'magenta'
            : 'rgba(255, 255, 255, 0)';

        const colorScale = this.getColorScale(domain);
        return _.isNil(value) ? missingDataColor : colorScale(value);
    }
}
