import { render, staticRenderFns } from "./BigMapSection.vue?vue&type=template&id=14b2f74e&scoped=true&"
import script from "./BigMapSection.vue?vue&type=script&lang=ts&"
export * from "./BigMapSection.vue?vue&type=script&lang=ts&"
import style0 from "./BigMapSection.vue?vue&type=style&index=0&id=14b2f74e&lang=postcss&scoped=true&"
import style1 from "./BigMapSection.vue?vue&type=style&index=1&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14b2f74e",
  null
  
)

export default component.exports