import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';

export const MetricDataType = t.type({
    value: t.union([t.string, t.number, t.null])
}, 'MetricData');

export const MetricByFipsDataType = t.type({
    fips: t.string,
    value: t.union([t.string, t.number, t.null])
}, 'MetricByFipsData');

export const MetricByDateDataType = t.type({
    cdate: DateFromISOString,
    value: t.union([t.string, t.number, t.null])
}, 'MetricByDateData');

export const MetricByFipsDataArrayType = t.array(MetricByFipsDataType);
export const MetricByDateDataArrayType = t.array(MetricByDateDataType);

export const TemporalByFipsDataType = t.type({
    fips: t.string,
    data: MetricByDateDataArrayType
});
export const TemporalByFipsDataArrayType = t.array(TemporalByFipsDataType);

export const BigNumberType = t.type({
    metricId: t.string,
    value: t.union([t.string, t.number, t.null])
}, 'BigNumber');
