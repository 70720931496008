import _ from 'lodash';
import { format as d3Format } from 'd3-format';
import { FormatOptions } from '@/types';
import { suffixes } from './config';

// gets the number of decimals required to mimic n.toPrecision(sigDecimals) for
// small numbers, ie. to get sigDecimals decimals after the last zero
export const getVerySmallDecimals = (n: number, sigDecimals = 1) => {
    if (Number(n) === 0) return 0;
    const zerosMatch = String(n).match(/\.(?<zeros>0+)/)?.groups?.zeros;
    return zerosMatch ? zerosMatch.length + sigDecimals : 0;
};

const getDecimals = (n: number) => {
    const absN = Math.abs(n);
    if (absN < 1000 && Number.isInteger(absN)) {
        return 0;
    }
    if (absN < 0.01) {
        return getVerySmallDecimals(absN, 2);
    }
    if (absN < 1) {
        return 2;
    }
    return 1;
};

const getPercentDecimals = (n: number) => {
    const absN = Math.abs(n * 10);
    if (Number.isInteger(absN)) {
        return 0;
    }
    if (absN < 0.1) {
        return 2;
    }
    if (absN < 1) {
        return 1;
    }
    if (absN >= 1) {
        return 0;
    }
    return 1;
};

const getDollarDecimals = (n: number) => {
    const absN = Math.abs(n);
    if (absN < 1000) {
        if (Number.isInteger(absN)) {
            return 0;
        }
        return 2;
    }
    return getDecimals(absN);
};

export const decimalGetters = {
    default: getDecimals,
    percent: getPercentDecimals,
    dollars: getDollarDecimals
};

// divide n by 1000 until it is below 1000, then return it and the number of divisions it took
export const truncateSingle = (n: number) => {
    let shortN = Math.abs(n);
    let divides = 0;
    const sign = n < 0 ? -1 : 1;
    while (shortN >= 1000) {
        shortN /= 1000;
        divides += 1;
    }
    return { value: sign * shortN, divides };
};

// divide all numbers in arr by 1000 until they are all below 1000,
// then return them and the number of divisions it took
export const truncateList = (arr: number[]) => {
    let shortArr = arr;
    let divides = 0;
    while (shortArr.some(n => Math.abs(n) >= 1000)) {
        shortArr = shortArr.map(n => Math.abs(n) / 1000);
        divides += 1;
    }
    return { values: shortArr, divides };
};

// return n with commas and a consistent number of decimals
export const longFormat = (n: number, type: string) => {
    if (Math.abs(n) < 1000) {
        const shortN = truncateSingle(n).value;
        const decimals = decimalGetters[type](shortN);
        return n.toFixed(decimals);
    }
    return d3Format(',d')(Math.round(n));
};

// get the number of decimals for which every number in the list is unique
export const getUniqueSmallDecimals = (matchFormatList: number[]) => {
    const numberParts = matchFormatList.map(t => ({
        number: t.toPrecision(12).split('.')[1],
        sign: Math.sign(t) < 0 ? '-' : ''
    }));

    let decimals = 1;
    let uniqueValues: { number: string; sign: string }[] = [];
    while (uniqueValues.length !== matchFormatList.length) {
        decimals += 1;
        if (decimals > 12) return null;
        uniqueValues = _.uniqBy(
            numberParts,
            // eslint-disable-next-line no-loop-func
            ({ number, sign }) => `${sign}${number.slice(0, decimals)}`
        );
    }
    return decimals;
};

// automatically find a number of decimals that work for all numbers in the list
// TODO: find a way to extract the numbers in here so we can make it configurable
// TODO: make it work with non-evenly-spaced numbers? if we have to?
export const getAutoDecimalFromList = (matchFormatList: number[], type: string): { decimals: number; divides: number } => {
    const { values, divides } = truncateList(matchFormatList);
    const scaledValues = type === 'percent' ? values.map(d => d * 100) : values;
    let decimals = 0;

    const diffBetweenNums = Math.abs(scaledValues[1] - scaledValues[0]);
    if (diffBetweenNums < 1) {
        if (type === 'dollars') {
            decimals = 2;
        } else {
            decimals = 1;
        }
    }
    if (diffBetweenNums < 0.1) {
        decimals = 2;
    }
    if (diffBetweenNums < 0.01) {
        const unique = getUniqueSmallDecimals(values);
        if (unique && Number.isFinite(unique)) {
            decimals = unique;
            if (type === 'percent') {
                decimals -= 2;
            }
        }
    }
    if (diffBetweenNums === 0) {
        decimals = 2;
    }
    return { decimals, divides };
};

// get the final number of decimals and divides to transform the number with
export const truncateParameters = (n: number, type: string, options: FormatOptions):
    { decimals: number; divides: number; singleDivides: number } => {
    const { matchFormatList } = options;
    const { divides: singleDivides } = truncateSingle(n);

    if (!matchFormatList) {
        return {
            decimals: decimalGetters[type](n),
            divides: singleDivides,
            singleDivides
        };
    }
    return {
        ...getAutoDecimalFromList(matchFormatList, type),
        singleDivides
    };
};

// use d3 format to get the SI suffix for a long number
export const getSISuffix = (n: number) => {
    const siSize = d3Format('s')(n)
        .match(/[a-zA-Z]+|[0-9]+(?:\.[0-9]+)?|\.[0-9]+/g);

    if (!siSize || siSize.length < 2) return '';
    return Math.abs(n) > 1 ? siSize[1] : '';
};

export const roundedFormat = (n: number, type: string, options: FormatOptions) => {
    const { decimals: optionDecimals, suffixLength } = options;
    if (n === 0) {
        return '0';
    }

    let { decimals, divides, singleDivides } = truncateParameters(n, type, options);
    if (!_.isNil(optionDecimals)) {
        decimals = optionDecimals;
    }

    const shortN = n / 1000 ** divides;
    const suffix = getSISuffix(n / 1000 ** (singleDivides - divides));

    return `${shortN.toFixed(decimals)}${
        suffixes[suffix][suffixLength || 'short']
    }`;
};

export const addUnits = (value: string, units?: string) => {
    if (units) {
        return `${value} ${units}`;
    }
    return value;
};
