import _ from 'lodash';
import { FormatOptions } from '../types';

import {
    addUnits,
    decimalGetters,
    getAutoDecimalFromList,
    longFormat,
    roundedFormat
} from './helpers';

export const formatDefault = (n: number | string, options?: FormatOptions): string => {
    const num = Number(n);
    if (Number.isNaN(num)) return '--';
    if (options?.truncate === false) {
        return longFormat(num, 'default');
    }
    return roundedFormat(num, 'default', { truncate: true, ...options });
};

export const formatDollar = (n: number | string, options?: FormatOptions): string => {
    const num = Number(n);
    if (Number.isNaN(num)) return '--';
    return `${num < 0 ? '-' : ''}$${roundedFormat(Math.abs(num), 'dollars', {
        truncate: true,
        ...options
    })}`;
};

export const formatPercent = (n: number | string, options?: FormatOptions): string => {
    const num = Number(n);
    if (Number.isNaN(num)) return '--';
    const optionsDecimals = options?.decimals;
    const decimals = !_.isNil(optionsDecimals)
        ? optionsDecimals
        : options?.matchFormatList
            ? getAutoDecimalFromList(options.matchFormatList, 'percent').decimals
            : decimalGetters.percent(num);
    return `${((num + Number.EPSILON) * 100).toFixed(decimals)}%`;
};

export const formatByType: Record<string, (n: number | string, options?: FormatOptions) => string> = {
    default: formatDefault,

    categorical: n => String(n),
    dollars: formatDollar,
    percent: formatPercent,
};

const format = (n: number | string, options?: FormatOptions) => {
    if (options?.type && _.has(formatByType, options.type)) {
        return addUnits(formatByType[options.type](n, options), options.units);
    }
    return addUnits(formatDefault(n, options), options?.units);
};

export default format;
