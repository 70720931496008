export const metricDataLayer: any = {
    id: 'metricData',
    source: 'metricData',
    type: 'fill',
    paint: {
        'fill-color': ['get', 'color'],
        'fill-outline-color': ['get', 'outlineColor'],
        'fill-opacity': 1
    },
    hoverable: true,
    clickable: true,
    beforeId: 'state-boundaries'
};
