import Color from 'color';
import { scaleQuantize } from 'd3-scale';
import { observable, ReactiveObject } from '@dha/vue-composition-decorators';

import DataService from '@/services/DataService';
import { GeojsonFeatureCollection } from '@/types';

import { ParsedGeoLevel } from '@/services/DataService/parsers';
import { filterGeojsonByProps, mapGeojsonProps } from '../helpers';
import { ISpatialDataset } from './SpatialDataset';

type RawFeatureProps = {
    fips: string;
}

type FullFeatureProps = {
    fips: string;
    value: string | number | null;
    displayValue: string;
    color: string;
    outlineColor: string;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IBoundaryLayer {
    getGeojsonWithPropsInjected(
        dataset: ISpatialDataset
    ): GeojsonFeatureCollection<FullFeatureProps>;
    getFilteredGeojson(
        callback: (props: RawFeatureProps) => boolean
    ): GeojsonFeatureCollection<RawFeatureProps>;
}

/* eslint-disable class-methods-use-this, @typescript-eslint/no-empty-function */
export class NullBoundaryLayer implements IBoundaryLayer {
    readonly colorScale = scaleQuantize<string, string>();
    readonly hasValuesBelowExtent = false;
    readonly hasValuesAboveExtent = false;
    getGeojsonWithPropsInjected() {
        return this.geojson;
    }
    getFilteredGeojson() {
        return this.geojson;
    }

    private readonly geojson: GeojsonFeatureCollection<FullFeatureProps> = {
        type: 'FeatureCollection',
        features: []
    };
}

export class BoundaryLayer extends ReactiveObject implements IBoundaryLayer {
    @observable.ref geojson: GeojsonFeatureCollection<RawFeatureProps>;
    geoLevel: ParsedGeoLevel;

    private constructor(
        geojson: GeojsonFeatureCollection<RawFeatureProps>,
        geoLevel: ParsedGeoLevel
    ) {
        super();
        this.geojson = geojson;
        this.geoLevel = geoLevel;
    }

    getFilteredGeojson(callback: (props: RawFeatureProps) => boolean) {
        return filterGeojsonByProps(
            this.geojson,
            callback
        );
    }

    outlineColor(color: string): string {
        const colorObj = Color(color);
        return colorObj.isDark()
            ? colorObj.lighten(0.2).string()
            : colorObj.darken(0.1).string();
    }

    getGeojsonWithPropsInjected(dataset: ISpatialDataset) {
        return mapGeojsonProps(this.geojson, props => {
            const fips = props.fips;

            const value = dataset.data[fips];
            const displayValue = dataset.getDisplayValue(fips);
            const color = dataset.getColor(fips);
            // const name = geography.getName(fips);
            // const population = geography.getPopulation(fips);
            const outlineColor = this.outlineColor(color);

            return {
                ...props,
                // name,
                value,
                displayValue,
                color,
                outlineColor,
                // population
            };
        });
    }

    static async new(dataService: DataService, geoLevel: ParsedGeoLevel): Promise<BoundaryLayer> {
        const geojson = await dataService.getGeojson(geoLevel, true);
        return new BoundaryLayer(geojson, geoLevel);
    }
}
