import _ from 'lodash';
import {
    ParsedMetadata,
    parseMetadata,
    ParsedMetricMetadata,
    ParsedMetricByFipsData,
    parseMetricByFipsDataArray,
    ParsedTemporalByFipsData,
    parseTemporalByFipsDataArray,
    parseGeojsonFeatureCollection,
    ParsedGeojsonFeatureCollection,
    ParsedGeoLevel,
    ParsedBigNumberData,
    parseBigNumber,
} from './parsers';
import { DataSource, QueryLatestFilters, QueryTemporalFilters } from './sources/DataSource';

export default class DataService {
    dataSource: DataSource;
    private metricMetadataByMetricId: Record<string, ParsedMetricMetadata> = {};
    private bigNumberMetricMetadataByMetricId: Record<string, ParsedMetricMetadata> = {};

    constructor(dataSource: DataSource) {
        this.dataSource = dataSource;
    }

    async init() {
        await this.dataSource.init();
        const [metadata] = await Promise.all([this.getMetadata()]);

        this.metricMetadataByMetricId = _(metadata.metrics)
            .keyBy((metric) => metric.metricId)
            .value();
        this.bigNumberMetricMetadataByMetricId = _(metadata.bigNumberMetrics)
            .keyBy((metric) => metric.metricId)
            .value();
    }

    async getMetadata(): Promise<ParsedMetadata> {
        const rawData = await this.dataSource.getMetadata();
        return parseMetadata(rawData);
    }

    async getLatestMetricData(metricId: string, filters: QueryLatestFilters): Promise<ParsedMetricByFipsData[]> {
        const metadata = this.metricMetadataByMetricId[metricId];
        const rawData = await this.dataSource.getLatestMetricData(metadata, filters);
        return parseMetricByFipsDataArray(rawData);
    }

    async getGeojson(level: ParsedGeoLevel, reprojected = false): Promise<ParsedGeojsonFeatureCollection> {
        const rawData = await this.dataSource.getGeojson(level, reprojected);
        return parseGeojsonFeatureCollection(rawData);
    }

    async getTemporalData(metricId: string, filters: QueryTemporalFilters): Promise<ParsedTemporalByFipsData[]> {
        const metadata = this.metricMetadataByMetricId[metricId];
        const rawData = await this.dataSource.getTemporalData(metadata, filters);
        return parseTemporalByFipsDataArray(rawData);
    }

    async getBigNumberData(metricId: string, filters: QueryTemporalFilters): Promise<ParsedBigNumberData> {
        const metadata = this.bigNumberMetricMetadataByMetricId[metricId];
        const rawData = await this.dataSource.getBigNumberData(metadata, filters);

        return parseBigNumber(rawData);
    }
}
