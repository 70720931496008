// import { ApiDataSource } from '@/services/DataService/sources/ApiDataSource';
import DataService from '@/services/DataService';
import { AppModel } from '@/model/AppModel';
import FileDataSource from '@/services/DataService/sources/FileDataSource';
import RegionSearchService from '@/services/RegionSearchService';

/**
 * Setup function to kick off the chain of model + service wiring. This is also
 * where feature toggles and environment config should be provided to the models
 * + services.
 *
 * IMPORTANT: This must be a _synchronous_ function so we can immediately return
 * the instances of models that should be provided to components. Async processes
 * can be _started_ here (such as appModel.init()), just not awaited
 */
export function setupModelsAndServices() {
    const fileDataSource = new FileDataSource('https://cvi-data-output.s3.amazonaws.com');
    const dataService = new DataService(fileDataSource);
    const appModel = new AppModel(dataService);
    const regionSearchService = new RegionSearchService(appModel);

    return {
        dataService,
        regionSearchService,
        appModel
    };
}
