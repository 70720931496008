export const suffixes = {
    '': {
        long: '',
        short: ''
    },
    k: {
        long: ' thousand',
        short: 'K'
    },
    M: {
        long: ' million',
        short: 'M'
    },
    G: {
        long: ' billion',
        short: 'B'
    },
    T: {
        long: ' trillion',
        short: 'T'
    },
    P: {
        long: ' quadrillion',
        short: 'Q'
    }
};
