







































































































































































import '@dha/vue-mapbox-gl/dist/vue-mapbox-gl.css';

import Vue, { PropType } from 'vue';
import _ from 'lodash';
import { GeojsonFeature, ValueDisplayDatum, VueWithTypedRefs } from '@/types';
import { BigMapModel } from '@/model/MapModel';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import MapboxGlMap, { Layer, Source } from '@dha/vue-mapbox-gl';
import { ParsedGeoLevel } from '@/services/DataService/parsers';
import { fipsToAbbrev, ALBERS_USA_BOUNDS, USA_PATHS } from '@/helpers';
import { paths } from './constants';

type HoverEvent = {
    features?: GeojsonFeature[];
    quadrant?: string;
};

type ComponentData = {
    // TODO: Real type
    hoveredFeature: Record<string, any> | null;
    tooltipAlignment: {
        horizontal: 'left' | 'right';
        vertical: 'bottom' | 'top';
    };
    paths: Record<string, string>;
};

export default (Vue as VueWithTypedRefs<{
    map: typeof MapboxGlMap;
}>).extend({
    components: {
        Tooltip,
        MapboxGlMap
    },
    props: {
        mapModel: {
            type: Object as PropType<BigMapModel>,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
    },
    data(): ComponentData {
        return {
            hoveredFeature: null,
            tooltipAlignment: {
                horizontal: 'left',
                vertical: 'bottom'
            },
            paths: {
                ...paths,
                ...USA_PATHS
            },
        };
    },
    computed: {
        accessToken(): string {
            return process.env.MAPBOX_ACCESS_TOKEN;
        },
        geoLevel(): ParsedGeoLevel {
            return this.mapModel.geoLevel;
        },
        layers(): Layer[] {
            return this.mapModel.dataError ? [] : this.mapModel.layers;
        },
        mapStyle(): string {
            return 'mapbox://styles/developmentdha/ckljignsq0xyu17p6e8srmlho';
        },
        sources(): Source[] {
            return this.mapModel.sources;
        },
        legendItems(): { label: string; color: string }[] {
            const labels = ['Very Low', 'Low', 'Moderate', 'High', 'Very High'];
            const colors = this.mapModel.metric.metadata.colors;
            return colors
                ? labels.map((label, index) => ({ label, color: colors[index] })).reverse()
                : [];
        },
        tooltipSelectedMetricValues(): ValueDisplayDatum[] {
            if (!this.hoveredFeature) return [];
            const hovered = this.hoveredFeature;
            return _.compact(
                this.mapModel.getTooltipSelectedMetricValues()
                    .map(formatted => formatted[hovered.fips])
            );
        },
        hoveredFeatureName(): string {
            if (!this.hoveredFeature) return '';

            const featureName = this.hoveredFeature.name;
            return this.geoLevel === 'county'
                ? `${featureName} County, ${fipsToAbbrev[this.hoveredFeature.fips.slice(0, 2)]}`
                : featureName;
        },
        downloadLinks(): { text: string; url: string }[] {
            return this.mapModel.bigMapLinks;
        },
        socialShareUri(): string {
            return encodeURIComponent('https://vaccine.precisionforcovid.org');
        }
    },
    mounted() {
        this.zoomToUsaBounds();
        // eslint-disable-next-line
        // @ts-ignore
        this.$refs.map.map.scrollZoom.disable();
    },
    methods: {
        onButtonClick(level: ParsedGeoLevel) {
            this.mapModel.setGeoLevel(level);
        },
        onMapHover({ features, quadrant }: HoverEvent) {
            this.hoveredFeature = features?.length ? features[0].properties : null;
            this.tooltipAlignment.horizontal = quadrant?.includes('left') ? 'left' : 'right';
            this.tooltipAlignment.vertical = quadrant?.includes('top') ? 'top' : 'bottom';
        },
        zoomIn() {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.map.map.zoomIn();
        },
        zoomOut() {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.map.map.zoomOut();
        },
        zoomToUsaBounds() {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.map.map.fitBounds(ALBERS_USA_BOUNDS, { animate: true, padding: 0 });
        }
    }
});
