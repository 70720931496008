import _ from 'lodash';
import { AsyncModel, computed, observable } from '@dha/vue-composition-decorators';
import DataService from '@/services/DataService';
import { AnyMetric } from '@/model/Metric';
import { ParsedGeoLevel } from '@/services/DataService/parsers';
import {
    BoundaryLayer,
    IBoundaryLayer,
    NullBoundaryLayer
} from '@/model/MapModel/Geojson/BoundaryLayer';
import {
    ISpatialDataset,
    NullSpatialDataset,
    SpatialDataset
} from '@/model/MapModel/Geojson/SpatialDataset';
import { metricDataLayer } from '@/model/MapModel/layers';
import { Source } from '@dha/vue-mapbox-gl';
import { NullMetric } from '@/model/Metric/NullMetric';
import { createSource, getFormattedTooltipData } from '@/model/MapModel/helpers';
import { GeoLevelMeta, ValueDisplayDatum } from '@/types';

export interface BaseMapModelParent {
    metricById: Record<string, AnyMetric>;
    geoLevelMeta: GeoLevelMeta;
}

export interface BigMapModelParent extends BaseMapModelParent {
    topMetricId: string;
    downloadLinks: Record<string, { text: string; url: string }>;
}

export interface DesertMapModelParent extends BaseMapModelParent {
    desertMetricId: string;
    downloadLinks: Record<string, { text: string; url: string }>;
}

abstract class MapModel<T extends BigMapModelParent | DesertMapModelParent> extends AsyncModel {
    dataService: DataService;
    parent: T;
    layers = [metricDataLayer];
    metricId = '';

    @observable geoLevel: ParsedGeoLevel;
    // @observable metricId: string = '';

    @observable dataError = false;
    @observable isLoading = true;

    @observable.ref data: ISpatialDataset = new NullSpatialDataset();
    @observable.ref dataLayer: IBoundaryLayer = new NullBoundaryLayer();

    @observable.ref tooltipDatasets: Record<string, Record<string, ISpatialDataset>> = {};
    @observable.ref values: Record<string, string | number | null> = {};

    constructor(
        dataService: DataService,
        parent: T,
        geoLevel: ParsedGeoLevel,
    ) {
        super();
        this.dataService = dataService;
        this.parent = parent;
        this.geoLevel = geoLevel;
    }

    abstract init(): Promise<void>;

    protected async _init() {
        await this.updateAll();
    }

    async updateAll() {
        try {
            this.isLoading = true;

            const [
                data,
                dataLayer,
                tooltipDatasets,
            ] = await Promise.all([
                SpatialDataset.new(this.dataService, this.metric, this.geoLevel),
                BoundaryLayer.new(this.dataService, this.geoLevel),
                this.getTooltipData(),
            ]);

            this.data = data;
            this.dataLayer = dataLayer;
            this.tooltipDatasets = tooltipDatasets;
            this.isLoading = false;
            this.dataError = false;
        } catch (err) {
            console.log('Error loading map data');
            console.error(err);
            this.isLoading = false;
            this.dataError = true;
        }
    }

    getFeatureFromFips(
        geoLevel: ParsedGeoLevel,
        fips: string
    ): { name: string; fips: string } | undefined {
        return _.find(this.parent.geoLevelMeta[geoLevel], { fips });
    }

    async setGeoLevel(level: ParsedGeoLevel) {
        this.geoLevel = level;
        await this.updateAll();
    }

    protected async getTooltipData() {
        return {
            [this.metricId]: {
                state: await SpatialDataset.new(
                    this.dataService,
                    this.parent.metricById[this.metricId],
                    'state'
                ),
                county: await SpatialDataset.new(
                    this.dataService,
                    this.parent.metricById[this.metricId],
                    'county'
                )
            },
        };
    }

    @computed get metric(): AnyMetric {
        return this.parent.metricById[this.metricId] ?? new NullMetric();
    }

    @computed get geojsonWithPropsInjected() {
        if (this.isLoading) {
            return this.dataLayer.getFilteredGeojson(() => false);
        }
        return this.dataLayer.getGeojsonWithPropsInjected(this.data);
    }

    @computed get sources(): Source[] {
        return [createSource('metricData', this.geojsonWithPropsInjected)];
    }

    getTooltipSelectedMetricValues(): Record<string, ValueDisplayDatum>[] {
        return [getFormattedTooltipData(this.tooltipDatasets[this.metricId][this.geoLevel])];
    }
}

export class BigMapModel extends MapModel<BigMapModelParent> {
    constructor(dataService: DataService, parent: BigMapModelParent) {
        super(dataService, parent, 'county');
    }

    async init() {
        this.metricId = this.parent.topMetricId;
        return this._init();
    }

    @computed get bigMapLinks(): { text: string; url: string }[] {
        const defaultLink = { text: '', url: '#' };

        return [
            this.parent.downloadLinks.DOWNLOAD_THE_DATA ?? defaultLink,
            this.parent.downloadLinks.DETAILED_METHODS ?? defaultLink
        ];
    }
}

export class DesertMapModel extends MapModel<DesertMapModelParent> {
    constructor(dataService: DataService, parent: DesertMapModelParent) {
        super(dataService, parent, 'county');
    }

    async init() {
        this.metricId = this.parent.desertMetricId;
        return this._init();
    }
}
