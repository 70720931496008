import _ from 'lodash';
import { DSVRowArray } from 'd3-dsv';
import { IncomingTemporalByFipsData, ParsedMetricByFipsData } from '../parsers';
import { QueryTemporalFilters } from './DataSource';

function toNumber(value?: string | number | null): number | null {
    if (!_.isNil(value)) {
        return value === 'NA' ? null : Number(value);
    }
    return null;
}

export function csvToMetricData(csv: DSVRowArray<string>, key: string): ParsedMetricByFipsData[] {
    return csv.map(row => ({
        fips: (row.fips ?? row.FIPS ?? row.GEOID)?.padStart(2, '0') as string,
        value: row[key] as string | number | null
    }));
}

export function csvToTemporalData(
    csv: DSVRowArray<string>,
    key: string,
    filters: QueryTemporalFilters
): IncomingTemporalByFipsData[] {
    const { geoLevel, fipsCode, stateFips } = filters;
    return _(csv)
        // this filter is probably temporary
        .filter(row => {
            if (geoLevel === 'state' || !stateFips) return true;
            const fips = row.fips ?? row.GEOID;
            return fips?.startsWith(stateFips) ?? false;
        })
        .groupBy(row => row.fips ?? row.GEOID)
        .entries()
        .filter(([fips]) => !fipsCode || fips === fipsCode)
        .map(([fips, data]) => ({
            fips: fips.padStart(2, '0'),
            data: data.map(row => ({
                cdate: row.date as string,
                value: toNumber(row[key])
            }))
        }))
        .value();
}

export function temporalCsvToLatestData(csv: DSVRowArray, key: string, fipsCode?: string): ParsedMetricByFipsData[] {
    const latestGrouped = _(csv)
        .groupBy(row => row.fips ?? row.GEOID)
        .mapValues(values => _.maxBy(values, 'date'))
        .value();
    const latest = _.values(latestGrouped).map(row => ({
        fips: row?.fips?.padStart(2, '0') ?? row?.GEOID?.padStart(2, '0') as string,
        value: row?.[key] as string | number | null
    }));
    return (fipsCode) ? latest.filter((entry) => entry.fips === fipsCode) : latest;
}
