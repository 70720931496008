import { TypeOf } from 'io-ts';
import { createParseFunction } from './helpers';
import { BaseBigNumberMetricMetadataType, MetadataType, MetricMetadataType, GeoLevelType, DataSourceKeyType } from './Metadata';
import {
    MetricByFipsDataType,
    MetricByFipsDataArrayType,
    MetricByDateDataType,
    MetricByDateDataArrayType,
    TemporalByFipsDataType,
    TemporalByFipsDataArrayType,
    BigNumberType,
} from './MetricData';
import { GeojsonFeatureCollectionType } from './Geojson';

export const parseMetadata = createParseFunction(MetadataType);
export const parseMetricByFipsDataArray = createParseFunction(MetricByFipsDataArrayType);
export const parseGeojsonFeatureCollection = createParseFunction(GeojsonFeatureCollectionType);
export const parseMetricByDateDataArray = createParseFunction(MetricByDateDataArrayType);
export const parseTemporalByFipsDataArray = createParseFunction(TemporalByFipsDataArrayType);
export const parseBigNumber = createParseFunction(BigNumberType);

export type BigNumberMetricMetadataType = TypeOf<typeof BaseBigNumberMetricMetadataType>;

export type ParsedMetadata = TypeOf<typeof MetadataType>;
export type ParsedMetricMetadata = TypeOf<typeof MetricMetadataType>;
export type ParsedGeoLevel = TypeOf<typeof GeoLevelType>;
export type ParsedGeojsonFeatureCollection = TypeOf<typeof GeojsonFeatureCollectionType>;
export type ParsedMetricByFipsData = TypeOf<typeof MetricByFipsDataType>;
export type ParsedMetricByDateData = TypeOf<typeof MetricByDateDataType>;
export type ParsedBigNumberData = TypeOf<typeof BigNumberType>;

export type IncomingTemporalByFipsData = {
    fips: string;
    data: { cdate: string; value: string | number | null }[];
};

export type IncomingBigNumbersData = {
    [key: string]: {
        high: string | number | null;
    };
};

// use io-ts-types to parse ISO string into Date
export type ParsedTemporalByFipsData = TypeOf<typeof TemporalByFipsDataType>;

export type DataSourceKey = TypeOf<typeof DataSourceKeyType>;
