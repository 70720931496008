import _ from 'lodash';
import { AsyncModel, computed, observable } from '@dha/vue-composition-decorators';
import DataService from '@/services/DataService';
import {
    ParsedGeojsonFeatureCollection,
    ParsedMetadata,
    ParsedMetricByFipsData
} from '@/services/DataService/parsers';
import { GeoLevelMeta, GeoProperties } from '@/types';
import { propsToString } from '@/helpers';
import { AnyMetric, metricFromMetadata } from './Metric';
import {
    BigMapModel,
    BigMapModelParent,
} from './MapModel';

export class AppModel extends AsyncModel implements BigMapModelParent {
    dataService: DataService;
    bigMapModel: BigMapModel;

    @observable.ref geojson: {
        state?: ParsedGeojsonFeatureCollection;
        county?: ParsedGeojsonFeatureCollection;
    };

    @observable topMetricId = '<loading>';
    @observable.ref metricById: Record<string, AnyMetric> = {};

    @observable.ref iconLinks: Record<string, { key: string; url: string }> = {};
    @observable.ref downloadLinks: Record<string, { text: string; url: string }> = {};

    @observable.ref metadata?: ParsedMetadata;
    @observable.ref stateTopMetricData: ParsedMetricByFipsData[] = []
    @observable.ref countyTopMetricData: ParsedMetricByFipsData[] = [];
    @observable isLoaded = false;

    constructor(dataService: DataService) {
        super();
        this.dataService = dataService;
        this.geojson = {};
        this.bigMapModel = new BigMapModel(dataService, this);
    }

    async init() {
        const [metadata, stateGeojson, countyGeojson] = await Promise.all([
            this.dataService.getMetadata(),
            this.dataService.getGeojson('state', true),
            this.dataService.getGeojson('county', true)
        ]);

        this.geojson = {
            state: stateGeojson,
            county: countyGeojson
        };

        this.metadata = metadata;
        this.metricById = _(metadata.metrics)
            .keyBy('metricId')
            .mapValues(metric => metricFromMetadata(metric))
            .value();

        const defaults = metadata.defaults;

        this.iconLinks = _(metadata.iconLinks)
            .keyBy('key')
            .mapValues(({ key, url }) => ({ key, url }))
            .value();

        this.downloadLinks = _(metadata.downloadLinks)
            .keyBy('key')
            .mapValues(({ text, url }) => ({ text, url }))
            .value();

        this.topMetricId = defaults?.topMetricId ?? metadata.metrics[0].metricId;

        const [
            stateTopMetricData,
            countyTopMetricData
        ] = await Promise.all([
            this.dataService.getLatestMetricData(
                this.topMetricId,
                { geoLevel: 'state', groupingId: '3q' }
            ),
            this.dataService.getLatestMetricData(
                this.topMetricId,
                { geoLevel: 'county', groupingId: '3q' }
            ),
            this.bigMapModel.init(),
        ]);

        this.stateTopMetricData = stateTopMetricData;
        this.countyTopMetricData = countyTopMetricData;
        this.isLoaded = true;
    }

    @computed get topLevelMetric(): AnyMetric {
        return this.metricById[this.topMetricId];
    }

    @computed get geoLevelMeta(): GeoLevelMeta {
        return {
            national: {
                99999: {
                    fips: '99999',
                    name: 'United States',
                    displayName: 'United States'
                }
            },
            state: _(this.geojson.state?.features)
                .map(f => ({
                    displayName: propsToString(f.properties as GeoProperties, 'state'),
                    ...f.properties
                }))
                .keyBy('fips')
                .value(),
            county: _(this.geojson.county?.features)
                .map(f => ({
                    displayName: propsToString(f.properties as GeoProperties, 'county'),
                    ...f.properties
                }))
                .keyBy('fips')
                .value()
        };
    }

    @computed get fipsByCVILevel(): Record<'state' | 'county', Record<string, string[]>> {
        return {
            state: _(this.stateTopMetricData)
                .groupBy('value')
                .mapValues(arr => arr.map(d => d.fips))
                .value(),
            county: _(this.countyTopMetricData)
                .groupBy('value')
                .mapValues(arr => arr.map(d => d.fips))
                .value()
        };
    }
}
