import './init';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import '@/model/injectAppModel';

Vue.config.productionTip = false;

export function runCVI(id = 'app') {
    return new Vue({
        router,
        render: h => h(App)
    }).$mount(`#${id}`);
}

runCVI();
